<template>
    <div class="rejected-documents">
        <div class="rejected-documents__heading">
            <img src="@/assets/icons/svg/remove.svg">
            Rejected Documents
        </div>
        <div class="rejected-documents__list">
            <div v-for="upload in taskRejectedUploads" :key="upload.name" class="rejected-documents__item">
                <span @click="toggleDocumentPreviewer(upload.id)">{{ taskFileName }}</span>
                -
                <strong>Submitted:</strong>
                {{ formatDate(upload.created_at) }}
                <button title="Rejection Reason" class="rejected-documents__reason"
                        @click="toggleReasonModal(upload.task_approval)">
                    <img src="@/assets/icons/svg/comment.svg">
                </button>
            </div>
        </div>
    </div>
    <DocumentPreviewer :visible="previewer.visible" :type-id="taskId" type="tasks" route="preview-uploaded-file"
                       :query="previewer.params" @close="toggleDocumentPreviewer"/>
    <AppModal content-class="reject-modal" :visible="reasonModal.visible" title="Document Rejected"
              text="Your partner has rejected your previous document for the reasons listed below."
              @close="toggleReasonModal">
        <p class="reject-modal__tip reject-modal__tip_red">
            Rejected: {{ formatDate(reasonModal.taskApproval.created_at) }}
        </p>
        <CNTextarea :model-value="reasonModal.taskApproval.notes" :disabled="true" class="form-control"
                    label="Reason" :max-length="1000"/>
        <template #footer>
            <CButton color="primary" @click="toggleReasonModal">Got it!</CButton>
        </template>
    </AppModal>
</template>

<script>

import DocumentPreviewer from "@/components/Documents/DocumentPreviewer.vue";
import AppModal from "@/components/Modals/AppModal.vue";
import Icon from "@/components/Template/Icon.vue";
import dateFormater from "@/mixin/dateFormater";

export default {
    name: "TaskPageRejectedDocuments",
    components: {Icon, AppModal, DocumentPreviewer},
    mixins: [dateFormater],
    props: {
        taskId: Number,
        taskFileName: String,
        taskRejectedUploads: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            previewer: {
                visible: false,
                params: null
            },
            reasonModal: {
                visible: false,
                taskApproval: null
            }
        }
    },
    methods: {
        toggleDocumentPreviewer(upload_id = null) {
            this.previewer.visible = !this.previewer.visible;
            this.previewer.params = {upload_id};
        },
        toggleReasonModal(taskApproval = null) {
            this.reasonModal.visible = !this.reasonModal.visible;
            this.reasonModal.taskApproval = taskApproval;
        }
    }
}
</script>

<style scoped lang="scss">
.rejected-documents {

    &__list {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__reason {
        background: none;
        border: none;
        outline: none;
        padding: 0 5px;
    }

    &__heading {
        display: flex;
        align-items: center;
        gap: 8px;
        color: rgba(28, 38, 47, 1);
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 12px;
    }

    &__item {
        color: rgba(28, 38, 47, 1);

        & span {
            color: rgba(0, 129, 194, 1);
            text-decoration: underline;
            cursor: pointer;
        }

        & strong {
            color: rgba(103, 122, 137, 1);
            font-weight: 700;
            font-size: 12px;
        }

        & svg {
            cursor: pointer;

            &:hover {

                & path {
                    fill: rgba(0, 129, 194, 1);
                }
            }
        }
    }
}
</style>
