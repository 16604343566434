<template>
    <div class="app-page task-page">
        <div class="task-page__content">
            <div class="task-page__body">
                <div class="task-page__section">
                    <span :title="task.project.name" class="task-page__project-name text-overflow-ellipsis">
                        Project: <strong class="">{{ task.project.name }}</strong>
                    </span>
                    <div class="task-page__task-name text-overflow-ellipsis">
                        <img v-if="task.is_main" src="@/assets/images/crown-emblem.svg" alt="crown"/>
                        <div :title="task.name" class="text-overflow-ellipsis">
                            <span>{{ task.name }}</span>
                        </div>
                    </div>
                    <div class="task-page__task-action">
                        <CIcon name="cilCheckCircle"/>
                        Action: {{ task.required_action }}
                    </div>
                    <p v-for="(inst, i) in instructions" :key="'inst' + i" class="task-page__task-instruction">
                        {{ instructions.length > 1 ? ++i + '.' : '' }} {{ inst }}
                    </p>
                </div>

                <div class="task-page__section">
                    <TaskPageDocument :task="task" @update="$emit('update')"
                                      @reject="toggleTaskDocumentRejectionModal"/>
                </div>

                <div v-if="taskRejectedUploads.length > 0" class="task-page__section">
                    <TaskPageRejectedDocuments :task-id="task.id" :task-file-name="task.file_name"
                                               :task-rejected-uploads="taskRejectedUploads"
                                               @showReason="modals.reason = $event"/>
                </div>

                <div class="task-page__section">
                    <span class="task-page__task-id">
                        Task ID: <strong>{{ task.task_id }}</strong>
                    </span>
                    <div class="task-page__form">
                        <div class="task-page__form-item">
                            <CNSelect v-model="formData.internal_assignee_id" :searchable="true" :caret="true"
                                      :class="{ 'task-page__readonly-field': isSub || !canEdit }"
                                      label="Internal Assignee" :disabled="isSub || !canEdit"
                                      :options="normalizeUsersList(task.internal_company.company_assigner_users)"
                                      @change="formDirty = true"/>
                        </div>
                        <div class="task-page__form-item">
                            <CNSelect v-model="formData.external_assignee_id" :searchable="true" :caret="true"
                                      label="External Assignee*" :disabled="!canEdit"
                                      :class="{ 'task-page__readonly-field': !canEdit }"
                                      :options="normalizeUsersList(task.external_company.company_assigner_users)"
                                      @change="formDirty = true"/>
                        </div>
                        <div v-if="showSignerFields" class="task-page__form-item">
                            <CNSelect v-model="formData.internal_signer_id" :searchable="true" :caret="true"
                                      label="Internal Signer*" :disabled="isSub || !canEdit"
                                      :options="normalizeUsersList(task.internal_company.company_signer_users)"
                                      :class="{ 'task-page__readonly-field': isSub || !canEdit }"
                                      @change="formDirty = true"/>
                        </div>
                        <div v-if="showSignerFields" class="task-page__form-item">
                            <CNSelect v-model="formData.external_signer_id" :searchable="true" :caret="true"
                                      label="External Signer*" :disabled="!canEdit"
                                      :class="{ 'task-page__readonly-field': !canEdit }"
                                      :options="normalizeUsersList(task.external_company.company_signer_users)"
                                      @change="formDirty = true"/>
                        </div>
                        <div class="task-page__form-item">
                            <CNSelect v-model="task.external_company.name" disabled
                                      :class="{ 'task-page__readonly-field': true }" label="Assigned Company"
                                      :options="[task.external_company.name]"/>
                        </div>
                        <div class="w-100">
                            <CNTextarea id="task-note" v-model="formData.note" :disabled="isSub || !canEdit"
                                        :class="{ 'task-page__readonly-field': isSub || !canEdit }"
                                        label="Description/Note:" style="height: 150px" :max-length="1000"
                                        @update:model-value="formDirty = true"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="task-page__panel">
                <div class="task-page__panel-category">Status</div>
                <div class="task-page__panel-statuses">
                    <div :class="['task-page__panel-status', `task-page__panel-status_${getStatusColor(task.status)}`]">
                        {{ task.status }}
                    </div>
                    <transition name="fade">
                        <div v-if="task.is_overdue" class="task-page__panel-status task-page__panel-status_red">
                            Overdue
                        </div>
                    </transition>
                </div>

                <div v-if="canApprove" class="task-page__panel-approve">
                    Action:
                    <button @click="toggleTaskDocumentApprovalModal">
                        <img src="@/assets/icons/svg/approve.svg">
                        Approve
                    </button>
                    <button @click="toggleTaskDocumentRejectionModal">
                        <img src="@/assets/icons/svg/remove.svg">
                        Reject
                    </button>
                </div>

                <div class="task-page__panel-category">Timeline</div>
                <FLCalendar v-model="formData.start_date" label="Assign Date"
                            :class="{ 'task-page__readonly-field': isSub || !canEdit }"
                            :disabled="isSub || !canEdit" @update:model-value="formDirty = true"/>
                <FLCalendar v-model="formData.due_date" label="Due Date"
                            :class="{ 'task-page__readonly-field': isSub || !canEdit }"
                            :disabled="isSub || !canEdit" @update:model-value="formDirty = true"/>
                <CNInput :model-value="formatDate(this.task.complete_date)" label="Completed Date"
                         :class="{ 'task-page__readonly-field': true }" :disabled="true"/>
                <CNSelect v-model="formData.priority" :class="{ 'task-page__readonly-field': isSub || !canEdit }"
                          label="Priority" :options="priorityOptions" :disabled="isSub || !canEdit"/>
            </div>
        </div>

        <StickyFooter v-if="showFooter">
            <CButton style="min-width: 100px" color="primary" variant="outline"
                     :disabled="loading || !canEdit || !formDirty" @click="toggleFormCancelModal">
                Cancel
            </CButton>
            <LoadingButton color="primary" :disabled="loading || !canEdit" :loading="loading"
                           style="min-width: 100px" @click="save">
                Save
            </LoadingButton>
            <CButton v-if="canRevoke" class="ml-auto d-flex align-items-center" variant="ghost"
                     :disabled="loading" @click="toggleTaskRevokeModal">
                <img src="@/assets/icons/svg/revoke.svg">
                <span class="text-error text-decoration-underline d-block ml-2">Revoke Task</span>
            </CButton>
        </StickyFooter>
    </div>

    <AppModal content-class="reject-modal" :visible="taskDocumentRejectionModal" title="Rejecting Document"
              text="Explaining why you reject this file will help your partner with providing the proper document"
              @close="toggleTaskDocumentRejectionModal">
        <p class="reject-modal__tip">Why do you reject this file?</p>
        <CNTextarea v-model="taskDocumentRejection.notes" :required="true" :invalid="!!validationErrors.notes"
                    @blur="validateField(taskDocumentRejection, taskDocumentRejectionRules, 'notes')"
                    class="form-control" label="Leave a comment here" :max-length="1000"/>
        <CFormText class="form-error">{{ validationErrors.notes }}</CFormText>
        <template #footer>
            <CButton color="primary" variant="outline" @click="toggleTaskDocumentRejectionModal">Cancel</CButton>
            <LoadingButton :loading="loading" color="danger" @click="rejectTaskDocument">Reject File</LoadingButton>
        </template>
    </AppModal>
    <AppModal content-class="reject-modal" :visible="taskDocumentApprovalModal"
              title="Are you sure you want to approve the file?" @close="toggleTaskDocumentApprovalModal">
        <template #footer>
            <CButton color="primary" variant="outline" @click="toggleTaskDocumentApprovalModal">Cancel</CButton>
            <CButton color="primary" @click="approveTaskDocument">Confirm</CButton>
        </template>
    </AppModal>
    <AppModal content-class="reject-modal" :visible="formCancelModal" title="Cancel changes?"
              text="Are you sure you want to cancel changes?" @close="toggleFormCancelModal">
        <template #footer>
            <CButton color="primary" variant="outline" @click="toggleFormCancelModal">No</CButton>
            <CButton color="primary" @click="cancel">Yes</CButton>
        </template>
    </AppModal>
    <AppModal content-class="reject-modal" :visible="taskRevokeModal" title="Are you sure you want to revoke the task?"
              text="This action can not be undone." @close="toggleTaskRevokeModal">
        <template #footer>
            <CButton color="primary" variant="outline" @click="toggleTaskRevokeModal">Cancel</CButton>
            <CButton color="primary" @click="revoke">Confirm</CButton>
        </template>
    </AppModal>
    <ConfirmDialog ref="confirmDialog"/>
</template>

<script>
import CNSelect from '@/components/ui/CNSelect/CNSelect.vue';
import CNTextarea from '@/components/ui/CNTextarea/CNTextarea.vue';
import StickyFooter from '@/components/Forms/Partials/StickyFooter.vue';
import FLCalendar from '@/components/Forms/Elements/FLCalendar.vue';
import CNInput from '@/components/ui/CNInput/CNInput.vue';
import {isEqual} from '@/utils/helper';
import ConfirmDialog from '@/components/Modals/ConfirmDialog.vue';
import TaskPageDocument from '@/components/TaskPages/TaskPageDocument.vue';
import {CLOSED_TASKS, FULLY_EXECUTED, PENDING_APPROVAL} from "@/domain/Entities/Task/taskStatuses";
import TaskPageRejectedDocuments from '@/components/TaskPages/TaskPageRejectedDocuments.vue';
import AppModal from '@/components/Modals/AppModal.vue';
import rules from "@/utils/validator/rules";
import Tasks from "@/api/v2/endpoints/Tasks";
import LoadingButton from "@/components/LoadingButton.vue";
import apiErrorHandler from "@/mixin/apiErrorHandler";
import {ACKNOWLEDGE_TEMPLATE_TYPE} from "@/domain/Entities/Template/templateTypes";
import {mapGetters} from "vuex";
import dateFormater from "@/mixin/dateFormater";

export default {
    name: 'TaskPage',
    components: {
        LoadingButton,
        AppModal,
        TaskPageRejectedDocuments,
        TaskPageDocument,
        ConfirmDialog,
        CNInput,
        FLCalendar,
        StickyFooter,
        CNTextarea,
        CNSelect,
    },
    inject: ['toast'],
    mixins: [apiErrorHandler, dateFormater],
    props: {
        task: {
            type: Object,
            required: true,
        },
        instructions: {
            type: Array,
            required: true,
        }
    },
    data() {
        return {
            loading: false,
            formCancelModal: false,
            taskRevokeModal: false,
            taskDocumentApprovalModal: false,
            taskDocumentRejectionModal: false,

            taskDocumentRejection: {
                notes: null
            },
            taskDocumentRejectionRules: {
                notes: [rules.required, rules.strMax(1000)],
            },

            formOrigin: null,
            formData: {
                internal_assignee_id: null,
                external_assignee_id: null,
                internal_signer_id: null,
                external_signer_id: null,
                assigned_company_id: null,
                note: null,
                start_date: null,
                due_date: null,
                priority: null,
            },

            priorityOptions: [
                'Normal',
                'High',
            ],
        };
    },
    computed: {
        ...mapGetters(['user']),
        taskRejectedUploads() {
            return this.task.uploads
                ? this.task.uploads.filter(upload => upload.task_approval?.is_approved === false)
                : [];
        },
        showFooter() {
            return this.task.status !== FULLY_EXECUTED && this.canEdit;
        },
        showSignerFields() {
            return this.task.type !== ACKNOWLEDGE_TEMPLATE_TYPE;
        },
        formDirty() {
            if (!this.formData || !this.formOrigin) return false;

            return !isEqual(this.formData, this.formOrigin);
        },
        isGeneral() {
            return this.task.internal_company.id === this.user.company.id;
        },
        isSub() {
            return this.task.external_company.id === this.user.company.id;
        },
        canEdit() {
            return this.$store.state.tasks.permissions.edit && !CLOSED_TASKS.includes(this.task.status);
        },
        canApprove() {
            return this.task.status === PENDING_APPROVAL && this.isGeneral;
        },
        canRevoke() {
            return this.isGeneral && this.canEdit;
        },
    },
    watch: {
        formDirty(val) {
            this.$emit('formDirtyChange', val);
        },
    },
    created() {
        this.setFormFields();
    },
    methods: {
        approveTaskDocument() {
            Tasks
                .approve(this.task.id)
                .then(() => {
                    this.toast('success', 'Task document has been successfully approved!');
                    this.$emit('update');
                })
                .catch(response => this.handleApiError(response))
                .finally(() => this.toggleTaskDocumentApprovalModal());
        },
        rejectTaskDocument() {
            this.toggleLoading();

            Tasks
                .reject(this.task.id, this.taskDocumentRejection)
                .then(() => {
                    this.toast('success', 'The document has been rejected');
                    this.$emit('update');
                })
                .catch(response => this.handleApiError(response))
                .finally(() => {
                    this.toggleLoading();
                    this.toggleTaskDocumentRejectionModal();
                });
        },
        revoke() {
            this.$http.tasks.revokeTask(this.task.id).then((res) => {
                this.toast('success', res.data.message);
                this.toggleTaskRevokeModal();
                this.$emit('update');
            });
        },
        save() {
            this.toggleLoading();

            this.$http.tasks
                .editTasks(this.defineTaskSaveData())
                .then(response => {
                    this.toast('success', response.data.message);
                    this.$emit('update');
                })
                .catch((e) => this.toast('success', e.response.data.message))
                .finally(() => this.toggleLoading());
        },
        defineTaskSaveData() {
            return {
                task_id: this.task.id,
                ...this.formData,
            };
        },
        uploadDocument() {
            this.$emit('upload');
        },
        normalizeUsersList(list) {
            return list.map(user => ({value: user.id, name: user.full_name}));
        },
        setFormFields() {
            this.formData.internal_assignee_id = this.task.internal_assignee_id;
            this.formData.external_assignee_id = this.task.external_assignee_id;
            this.formData.internal_signer_id = this.task.internal_signer_id;
            this.formData.external_signer_id = this.task.external_signer_id;
            this.formData.start_date = this.task.start_date;
            this.formData.due_date = this.task.due_date;
            this.formData.note = this.task.note;
            this.formData.priority = this.task.priority;
            this.formOrigin = {...this.formData};
        },
        getStatusColor(status) {
            const data = {
                'Signature Required': 'violet',
                Overdue: 'red',
                Cancelled: 'red',
                'Fully Executed': 'green',
                Completed: 'green',
                'Pending Approval': 'green',
                'Pending Acknowledgement': 'light-blue',
                'Pending Submission': 'dark-blue',
                Revoked: 'red',
                'Scheduled to Send': 'orange',
            };

            return data[status];
        },
        cancel() {
            this.formData = {...this.formOrigin};
            this.toggleFormCancelModal();
        },
        toggleLoading() {
            this.loading = !this.loading;
        },
        toggleFormCancelModal() {
            this.formCancelModal = !this.formCancelModal;
        },
        toggleTaskRevokeModal() {
            this.taskRevokeModal = !this.taskRevokeModal;
        },
        toggleTaskDocumentApprovalModal() {
            if (this.taskDocumentApprovalModal || this.checkIfCanApproveOrReject()) {
                this.taskDocumentApprovalModal = !this.taskDocumentApprovalModal;
            }
        },
        toggleTaskDocumentRejectionModal() {
            if (this.taskDocumentRejectionModal || this.checkIfCanApproveOrReject()) {
                this.taskDocumentRejectionModal = !this.taskDocumentRejectionModal;
                this.taskDocumentRejection.notes = null;
            }
        },
        checkIfCanApproveOrReject() {
            const check = this.task.internal_assignee_id === this.user.id;

            if (!check)
                this.notifyUserOnApprovalPermissions();

            return check;
        },
        notifyUserOnApprovalPermissions() {
            this.$notify({
                type: 'info',
                content: 'Only the internal assignee has permissions to approve or reject the document',
            });
        }
    },
};
</script>

<style lang="scss">
.reject-modal {
    width: 688px;

    & .form-control {
        border: none;
        padding: 0;
    }

    &__tip {
        font-size: 12px;
        font-weight: 600;
        color: rgba(103, 122, 137, 1);

        &_red {
            color: red !important;
        }
    }

    & .common-modal__buttons {
        & .btn {
            min-width: 200px;
        }

        & .btn-danger {
            color: white;
        }
    }
}

.task-page {
    & .form-floating {
        & label {
            color: #677a89 !important;
            font-size: 15px !important;
        }
    }

    &__form-field {
        width: 100%;
        border: 1px solid #9fafbc;
        border-radius: 8px;
        font-size: 16px;

        & .due-date__selector {
            background: #fff;
        }
    }

    &__modal {
        width: 688px;

        &-icon {
            height: 70px !important;
            width: 70px !important;
            color: #677a89;
        }
    }

    &__readonly-field {
        opacity: 0.8;
    }

    &__disabled-field {
        opacity: 0.8;
        pointer-events: none;
    }

    &__content {
        display: flex;
        align-items: flex-start;
        gap: 24px;
        padding-bottom: 80px;
    }

    &__body {
        max-width: 850px;
    }

    &__panel {
        background: #f2f5f8;
        padding: 16px;
        width: 324px;
        min-height: 573px;
        flex-grow: 0;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        gap: 24px;

        &-approve {
            display: flex;
            align-items: center;
            gap: 16px;

            & button {
                display: flex;
                align-items: center;
                background: none;
                border: none;
                outline: none;
                gap: 8px;
                color: rgba(28, 38, 47, 1);
                font-weight: 600;
                font-size: 14px;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &-statuses {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
        }

        &-status {
            height: 24px;
            padding: 0 12px;
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 14px;
            color: white;
            border-radius: 48px;

            &_green {
                background: rgba(11, 189, 153, 1);
            }

            &_blue {
                background: rgba(0, 86, 148, 1);
            }

            &_light-blue {
                background: rgba(0, 129, 194, 1);
            }

            &_dark-blue {
                background: rgba(4, 53, 102, 1);
            }

            &_orange {
                background: rgba(255, 145, 0, 1);
            }

            &_violet {
                background: #b12cf1;
            }

            &_red {
                background: #ff1f26;
            }
        }

        &-category {
            font-size: 18px;
            font-weight: 600;
            color: #1c262f;
            padding-bottom: 8px;
            border-bottom: 1px solid #9fafbc;
        }
    }

    &__section {
        margin-bottom: 32px;
    }

    &__project-name {
        font-size: 16px;
        color: #677a89;
        display: block;
        margin-bottom: 24px;

        & strong {
            font-weight: 600;
            font-size: 16px;
        }
    }

    &__task-name {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 24px;
        font-weight: 600;
        color: #1c262f;
        border-bottom: 1px solid #c3cdd5;
        margin-bottom: 24px;

        & img {
            height: 24px;
        }
    }

    &__task-action {
        display: flex;
        align-items: center;
        gap: 4px;
        font-weight: 600;
        font-size: 18px;
        color: #1c262f;
        margin-bottom: 12px;

        & svg {
            color: #677a89;
        }
    }

    &__task-instruction {
        font-size: 16px;
        color: #1c262f;
        margin-bottom: 0;
    }

    &__file-area {
        display: flex;
        align-items: center;
        padding: 0 16px;
        min-height: 56px;
        border-radius: 8px;
        margin-bottom: 12px;

        &_green {
            border: 1px dashed #008a6e;
            background: rgba(159, 233, 218, 0.24);
        }

        &_blue {
            border: 1px dashed #00437a;
            background: rgba(171, 231, 250, 0.25);
        }

        &-title {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 16px;
            font-weight: 600;
            color: #1c262f;
            margin-bottom: 12px;

            & svg {
                color: #0068ad;
            }
        }
    }

    &__file-link {
        color: #0068ad;
        font-size: 16px;
        text-decoration: underline;
    }

    &__task-id {
        display: block;
        margin-bottom: 24px;
        font-size: 16px;
        color: #1c262f;

        & strong {
            font-weight: 600;
        }
    }

    &__form {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;

        & .form-control {
            border: none;
            padding: 0;
        }

        &-item {
            width: 348px;
        }
    }
}
</style>
